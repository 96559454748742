import { GetStaticPaths, GetStaticProps } from 'next';
import Layout from '../layouts/Layout';
import { queryGraphQl } from '../lib/apolloClient';
import { isArray } from '@apollo/client/utilities';
import AllPagesQuery from '../graphql/queries/AllPagesQuery.graphql';
import ContentPageQuery from '../graphql/queries/ContentPageQuery.graphql';
import { IContentItem, PathsData } from 'src/interfaces/IAllPagesQuery';
import { IContentPageQueryResponse } from 'src/interfaces/IContentPageQuery';
import ConfigPageQuery from '@graphql/queries/ConfigPageQuery.graphql';
import MenuBlockQuery from '@graphql/queries/MenuBlockQuery.graphql';
import { IConfigPage } from '@interfaces/IConfigPage';

// Add constant for excluded content types
const EXCLUDED_CONTENT_TYPES = ['ConfigPage', 'ListingPage', 'PartnerPage'];

const getStandardPagesUrls = async (): Promise<{ params: { slug: string[] } }[]> => {
  try {
    const response = await queryGraphQl<PathsData>(AllPagesQuery, {});
    const allItems = response?.Content?.items || [];
    const paths = allItems
      .filter((page: IContentItem) => {
        // Skip undefined/null pages
        if (!page) return false;

        // Skip if EXCLUDED_CONTENT_TYPES item exists in ContentTypes Array
        if (EXCLUDED_CONTENT_TYPES.some((type) => page.ContentType.includes(type))) return false;

        return true;
      })
      .map((page: IContentItem) => ({
        params: {
          slug: `${page.RelativePath}`.split('/').filter(Boolean),
        },
      }));
    return paths || [];
  } catch (error) {
    console.error('Failed to fetch standard page URLs (getStandardPagesUrls()):', error);
    return [];
  }
};

interface IGraphQLResponse {
  ConfigPage: {
    items: IConfigPage[];
  };
}

const processChildMenuItems = (items: any): any[] => {
  if (!items) return [];
  return items.map((item: any) => item);
};

const processRootMenuItems = async (items: any[], lang: string): Promise<any[]> => {
  const processedItems = [];
  for (const item of items) {
    const expandedItem = item.ContentLink?.Expanded || item;
    if (expandedItem.__typename === 'MenuBlock') {
      const childrenData = await queryGraphQl<any>(MenuBlockQuery, {
        id: expandedItem.ContentLink?.Id,
        locale: lang,
      });
      if (childrenData) {
        const children = processChildMenuItems(childrenData.MenuBlock.items);
        processedItems.push({ ...item, children });
      }
    } else {
      processedItems.push(item);
    }
  }
  return processedItems;
};

interface PageProps {
  globalData: any;
  data: any;
}

const Page: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout
      data={data}
      isAdmin={false}
    ></Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  try {
    const slug = isArray(params?.slug) ? params?.slug.join('/') : '/';
    const language = slug.split('/')[0] || 'en';
    const globalData = await fetchDataConfig(params);
    const data = await queryGraphQl<IContentPageQueryResponse>(ContentPageQuery, {
      routeSegment: '/' + slug,
      locale: language,
    });

    if (!data?.Content?.items?.[0]) {
      return { notFound: true };
    }

    const pageData = data.Content.items[0];

    if (EXCLUDED_CONTENT_TYPES.some((type) => pageData.ContentType?.includes(type))) {
      return { notFound: true };
    }

    return {
      props: {
        globalData: globalData || {},
        data: pageData,
      },
      revalidate: 10,
    };
  } catch (error) {
    console.error('Error in getStaticProps:', error);
    return { notFound: true };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  try {
    const paths = await getStandardPagesUrls();
    return {
      paths,
      fallback: 'blocking',
    };
  } catch (error) {
    console.error('Error generating static paths (getStaticPaths()):', error);
    return {
      paths: [],
      fallback: 'blocking',
    };
  }
};

const fetchDataConfig = async (params: any) => {
  const lang = params?.slug ? params?.slug[0] : 'en';

  const result = await queryGraphQl<IGraphQLResponse>(ConfigPageQuery, {
    locale: lang,
  });

  if (!result || !result.ConfigPage || !result.ConfigPage.items || !result.ConfigPage.items[0]) {
    return {};
  }

  const resItem: any = result.ConfigPage.items[0];

  const socialMediaLinks = {
    FacebookLink: resItem.FacebookLink,
    InstagramLink: resItem.InstagramLink,
    LinkedInLink: resItem.LinkedInLink,
    YoutubeLink: resItem.YoutubeLink,
    TwitterLink: resItem.TwitterLink,
  };
  const translation = resItem.Labels ? safeJSONParse(resItem.Labels) : {};
  const headerMenu = resItem.HeaderMenu.Items ? await processRootMenuItems(resItem.HeaderMenu.Items, lang) : [];
  const footerMainMenu = resItem.FooterMenu.Items ? await processRootMenuItems(resItem.FooterMenu.Items, lang) : [];
  const bottomMenu = resItem.FooterMenu.Items ? await processRootMenuItems(resItem.BottomMenu.Items, lang) : [];
  return { headerMenu, footerMainMenu, socialMediaLinks, translation, bottomMenu };
};

function safeJSONParse(str: any) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
}

export default Page;
